<template>
    <div class="big-font" style="margin: 8px">
        <div class="row pa-3">
            <div class="col-12 col-md-4 col-lg-3">
                <a href="https://www.lakalodge.com">
                    <v-icon>mdi-arrow-left-circle-outline</v-icon>
                    Back to Laka Lodge website</a><br>
                <div class="reservation">For reservation mail to: <a style="rgba(255,255,255,0.9) !important" href="mailto:info@lakalodge.com">info@lakalodge.com</a>
                </div>
            </div>
            <div class="v-calendar col-12 col-md-8 col-lg-5 mt-2">
                Rooms available: <br>
                <div class="room-legend v-event v-event-start v-event-end white--text text-center"
                     v-bind:class="get_color('B')">
                    Bungalow
                </div>
                <div class="room-legend v-event v-event-start v-event-end white--text text-center"
                     v-bind:class="get_color('SR')">
                    Superior
                </div>
                <div class="room-legend v-event v-event-start v-event-end white--text text-center"
                     v-bind:class="get_color('SO')">
                    Ocean
                </div>
                <div class="room-legend v-event v-event-start v-event-end white--text text-center"
                     v-bind:class="get_color('SP')">
                    Panoramic
                </div>
            </div>
            <div class="col-12 col-lg-4 mt-2">
                <div class="v-calendar">
                    <span class="text-right" style="width: 50px">
                        <div class="room-legend v-event v-event-start v-event-end white--text text-center"
                             v-bind:class="get_color('SO')" style="width: 46px">
                            O 2
                        </div>
                    </span>
                    <span>
                        = 2 ocean suites available
                    </span>
                </div>
                <div>
                    <span class="text-right" style="width: 50px">
                        <v-icon class="icon">mdi-diving-snorkel</v-icon>
                        <span class="dive-amount-circle">
                        <v-icon>mdi-card</v-icon>
                        <span class="dive-amount">12</span>
                    </span>
                    </span>
                    <span style="margin-left: 8px">
                        = 12 dive slots available
                    </span>
                </div>
            </div>
        </div>

        <div
                tile
                height="52"
                class="d-flex"
        >
            <v-spacer></v-spacer>
            <v-btn
                    icon
                    medium
                    elevation="5"
                    class="ma-2"
                    @click="$refs.calendar.prev()"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <h1 v-if="$refs.calendar" class="titlemonth">{{$refs.calendar.title}}</h1>
            <v-btn
                    icon
                    medium
                    elevation="5"
                    class="ma-2"
                    @click="$refs.calendar.next()"
            >
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
        </div>
        <v-sheet height="1200">
            <v-calendar
                    ref="calendar"
                    v-model="value"
                    :weekdays="weekday"
                    :type="type"
                    :events="events"
                    :event-overlap-mode="mode"
                    :event-overlap-threshold="30"
                    :event-color="getEventColor"
                    :event-more=false
                    :event-text-color="getEventTextColor"
                    :event-height="17"
                    :min-weeks="9"
                    :locale="locale"
                    @input="day_press"
            >
                <template v-slot:day="{date}">
                    <div :id="date" v-if="dives[date] > 0 && (new Date(date) > Date.now())">
                        <div class="diver">
                            <v-icon class="icon">mdi-diving-snorkel</v-icon>
                            <span class="dive-amount-circle">
                                <v-icon>mdi-card</v-icon>
                                <span class="dive-amount">{{dives[date]}}</span>
                            </span>
                        </div>
                    </div>
                </template>
                <template v-slot:event="{event}">
                    <span v-html="event.name" style="padding-left: 5px">{{event.name}}</span>
                </template>
            </v-calendar>
        </v-sheet>
    </div>
</template>

<script>
    import axios from 'axios';
    import csv from 'papaparse';

    export default {
        data: () => ({
            type: 'month',
            mode: 'stack',
            weekday: [1, 2, 3, 4, 5, 6, 0],
            value: '',
            events: [],
            colors: ['light-blue', 'orange', 'green', 'grey darken-1'],
            dives: {},
            locale: "en",
            previous_clicked_date: "",
            shorter_types_map: new Map([["B", "B"], ["SR", "S"], ["SO", "O"], ["SP", "P"]]),
        }),
        mounted() {
            this.getEvents();
            let getLanguage = navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en'
            this.locale = getLanguage
        },
        methods: {
            get_top: function (date) {
                if (document.getElementById(date)) {
                    return document.getElementById(date).top
                } else {
                    return ""
                }
            },
            day_press() {
                //scroll back up if click outside of current month
                this.previous_clicked_date = new Date(this.$refs.calendar.value)
                setTimeout(this.scroll_up, 100);
            },
            scroll_up() {
                let clicked_date = this.$refs.calendar.value
                clicked_date = new Date(clicked_date)
                if (this.previous_clicked_date === "" || clicked_date.getMonth() !== this.previous_clicked_date.getMonth()) {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                }
                this.previous_clicked_date = clicked_date
            },
            async getEvents() {
                let DATE_TYPE = "date"
                let DIVES_TYPE = "dives"
                let response = await axios.get('https://www.dl.dropboxusercontent.com/s/l5dfs4zf30sh99s/occup.csv')
                let data = response.data
                let a = csv.parse(data).data
                // array of the rooms
                let header_titles = a[0] //header used in the csv
                let header_types = [] //header_titles but only as type
                let room_types = [] //only the types of rooms, no duplicates
                let dives = {} // dives possible with key: date, value: amount possible
                let max_capacity = {}
                let date_type_index = null
                let dives_type_index = null
                for (let i = 0; i < header_titles.length; i++) {
                    let type = header_titles[i].split("-")[0]
                    header_types.push(type)
                    //add to room types and calculate max capacity
                    if (type === DATE_TYPE) {
                        date_type_index = i
                    } else if (type === DIVES_TYPE) {
                        dives_type_index = i
                    } else {
                        room_types.push(type)
                        if (!(type in max_capacity)) {
                            max_capacity[type] = 1
                        } else {
                            max_capacity[type] += 1
                        }
                    }
                }
                // give certain rooms a certain place in the list and make them unique
                room_types = this.sort_room_types_unique(room_types)

                //get capacity
                let days = []
                // loop over data
                for (let line_index = 1; line_index < a.length; line_index++) {
                    let day_array = a[line_index]
                    if (day_array[0] === "") {
                        continue;
                    }

                    let day = {}
                    // initialize starting filled amount for rooms
                    room_types.forEach(room_type => day[room_type] = 0)
                    for (let day_index = 0; day_index < day_array.length; day_index++) {
                        let header = header_types[day_index]
                        if (room_types.includes(header)) {
                            day[header] += parseInt(day_array[day_index])
                        }//else is not a room
                    }
                    //add the date
                    let date = day_array[date_type_index]
                    day[DATE_TYPE] = date
                    //add amount of dives
                    let dives_possible = day_array[dives_type_index]
                    day[DIVES_TYPE] = dives_possible
                    dives[date] = dives_possible
                    days.push(day)
                }

                let connected = false;
                //loop over rooms
                //check per day if it is an end, add event from the start value
                const events = []
                if (connected) {
                    for (let i = 0; i < room_types.length; i++) {
                        let room_type = room_types[i]
                        let start = ""
                        for (let i = 0; i < days.length; i++) {
                            let day = days[i]
                            let current_date = day[DATE_TYPE]
                            if (new Date(current_date) < Date.now()) {
                                continue
                            }
                            let current_occupied = day[room_type]
                            if (current_occupied < max_capacity[room_type]) {
                                //add start. If start exists, skip.
                                if (start === "") {
                                    // year - month - day
                                    start = current_date
                                }
                            } else {
                                //end of an interval. If start exists, make event
                                if (start !== "") {
                                    let previousDate = days[i - 1][DATE_TYPE]; // year - month - day from date before
                                    events.push({
                                        name: this.get_name(room_type),
                                        start: new Date(start),
                                        end: new Date(previousDate),
                                        color: this.get_color(room_type)
                                    })
                                    start = ""
                                }
                            }
                        }
                        //see if there is an open end
                        if (start !== "") {
                            let previousDate = days[days.length - 1 - 1][DATE_TYPE]
                            events.push({
                                name: this.get_name(room_type),
                                start: new Date(start),
                                end: new Date(previousDate),
                                color: this.get_color(room_type),
                            })
                        }
                    }
                } else {
                    //##!! separate days with more text
                    for (let i = 0; i < room_types.length; i++) {
                        let room_type = room_types[i]
                        let start = ""
                        for (let i = 0; i < days.length; i++) {
                            let day = days[i]
                            let current_date = day[DATE_TYPE]
                            if (new Date(current_date) < Date.now()) {
                                continue
                            }
                            let current_occupied = day[room_type]
                            //add start.
                            // year - month - day
                            start = current_date
                            let short_room_type = this.shorten_type(room_type)
                            if (current_occupied < max_capacity[room_type]) {
                                //end of an interval on same day
                                let available = max_capacity[room_type] - current_occupied
                                //let name = this.get_name(room_type) + " [" + available + "/" + max_capacity[room_type] + "]"
                                let name = short_room_type + "&nbsp;&nbsp;&nbsp;" + available
                                events.push({
                                    name: name,
                                    start: start,
                                    end: start,
                                    color: this.get_color(room_type)
                                })
                            } else {
                                // add not available room to keep all rooms on same level
                                // color keyword is added to the class
                                events.push({
                                    name: short_room_type + "&nbsp;&nbsp;&nbsp;" + 0,
                                    start: start,
                                    end: start,
                                    color: this.get_color(room_type) + " hatch-grey"
                                })
                            }
                        }
                    }
                }
                this.events = events
                this.dives = dives
            },
            getEventColor(event) {
                return event.color
            },
            getEventTextColor() {
                return "white"
            },
            rnd(a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            get_color(type) {
                switch (type) {
                    case "B":
                        return this.colors[0]
                    case "SR":
                        return this.colors[1]
                    case "SO":
                        return this.colors[2]
                    case "SP":
                        return this.colors[3]
                }
            },
            shorten_type(type) {
                return this.shorter_types_map.get(type);
            },
            get_name(type) {
                switch (type) {
                    case "B":
                        return "Bungalow"
                    case "SR":
                        return "Superior"
                    case "SO":
                        return "Ocean"
                    case "SP":
                        return "Panoramic"
                }
            },
            /**
             * sort an array of room types in the desired order, keeping extra rooms at the end
             * Also makes the types unique (removes duplicates). Does not add new room types
             * @param room_types a String array of room types
             */
            sort_room_types_unique(room_types) {
                let desired_room_order = ["B", "SR", "SO", "SP"]
                let desired_rooms_filtered = desired_room_order.filter(value => room_types.includes(value))
                room_types = desired_rooms_filtered.concat(room_types)
                let rooms_filtered = room_types.filter((v, i, a) => a.indexOf(v) === i);
                return rooms_filtered
            }
        },
    }
</script>

<style>
    .reservation {
        padding: 8px;
        background-color: rgba(60, 65, 68, 0.99);
        color: rgba(255,255,255,0.9);
        box-shadow: 2px 5px 8px 0 #888;
        border-radius: 5px;
    }

    .v-calendar .room-legend {
        display: inline-block;
        width: 110px;
        height: 20px;
        margin-right: 10px !important;
        font-size: 16px !important;
    }

    .titlemonth {
        width: 300px;
        align-content: center;
        text-align: center;
        /*padding: 50px;*/
        /*margin: 20px;*/
    }

    .diver {
        top: 30px;
        left: 5px;
        position: absolute;
    }

    .diver .icon {
        font-size: 20px !important;
    }

    .v-icon {
        color: dodgerblue !important;
    }

    /* circle amount of dives is drawn on */
    span.dive-amount-circle {
        position: relative;
        font-size: 14px;
        top: -1px;
        right: 1px;
    }

    /* amount of dives number */
    span.dive-amount {
        position: absolute;
        top: 0px !important;
        color: #fff;
        right: 4px;
        text-align: center;
    }

    .v-calendar-weekly__day-label {
        margin-bottom: 12px !important;
    }

    @media (min-width: 576px) {
        .v-calendar-weekly__day-label {
            position: relative;
            margin-bottom: 14px !important;
        }
    }

    @media (min-width: 992px) {
        .v-calendar-weekly__day-label {
            margin-bottom: -5px !important;
        }

        .diver {
            top: 10px;
        }
    }


    /* Tooltip text */
    .tooltip .tooltiptext {
        visibility: hidden;
        min-width: 250px;
        background-color: #555;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        /* Show line per line */
        display: inline-block;

        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;

        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
    }

    /* Tooltip arrow */
    .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 28%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }

    /* disable mouse hover icon for events */
    .v-event {
        pointer-events: none;
        margin-left: auto !important;
        margin-right: auto !important;
    }


    /* disable hover for day, but enable for date button */
    .v-calendar-weekly__day-label:hover {
        cursor: default;
    }

    .v-btn:hover {
        cursor: pointer;
    }

    .v-application .reservation a {
        color: white !important;
    }

    .hatch-grey {
        opacity: 25%;
        background-image: linear-gradient(to right, rgba(255,255,255,0),  rgba(255,255,255,0.3), rgba(255,255,255,1));
    }
</style>